import { useCallback } from 'react';

import Grid from '@mui/material/Grid2';

import Cartouche from "./Cartouche";

function Librairie({ajouterALaListeDeLecture, listeMorceaux, taille})
{
    // callbacks
    // méthode de tri des morceaux par date et heure d'ajout
    const triDateAjout = useCallback((a, b) =>
    {
        return a.dateAjout > b.dateAjout ? 1 : a.dateAjout < b.dateAjout ? -1 : 0;
    }, []);

    // JSX du composant
    return (
        <Grid container>
            {
                listeMorceaux.sort(triDateAjout).map((morceau) => 
                    <Grid key={"morceau_" + morceau.id} size={taille}>
                        <Cartouche morceau={morceau} ajouterALaListeDeLecture={ajouterALaListeDeLecture} />
                    </Grid>
                )
            }
        </Grid>
    );
}

export default Librairie;