import { useMemo, useCallback } from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import "../App.css";

import "../font/CornucopiaOfOrnamentsFour.ttf"
import "../font/HeroesLegendHollow.ttf"
import "../font/Yokelvision.otf"

import Canevas from '../shared/Canevas';
import { tracerRectangle, tracerLigne } from '../shared/CanevasDessins';
import { nullOuIndefini, validerFonction } from '../shared/Validations';

function Cartouche({morceau, ajouterALaListeDeLecture}) 
{
    // memos
    // dimensions de la cartouche
    const hauteurCartouche = useMemo(() => 100, []);
    const largeurCartouche = useMemo(() => window.innerWidth / 4, []);
    // comportement du curseur au survol, selon que l'on a ou pas une fonction de rappel dans les props pour réagir au clic
    const curseur = useMemo(() => validerFonction(ajouterALaListeDeLecture) ? "pointer" : "default", [ ajouterALaListeDeLecture ]);

    // callbacks
    // capteur d'événement au clic sur un cartouche : on déclenche l'ajout du morceau dans la file d'attente
    const actionClick = useCallback(() =>
    {
        if (validerFonction(ajouterALaListeDeLecture))
        {
            ajouterALaListeDeLecture(morceau.id);
        }
    }, [ ajouterALaListeDeLecture, morceau ]);

    // méthode de tracé dans le canevas du cartouche
    const dessiner = useCallback((contexte) => 
    {
        if (nullOuIndefini(contexte))
        {
            return;
        }

        if (validerFonction(contexte.reset)) 
        {
            contexte.reset();
        }

        // on ajoute l'encadrement rouge orangé de l'auteur
        // d'abord un 1er trait centré à gauche, deux traits obliques à 45° vers le haut et vers le base

        const couleurLignes = "#E88284";

        tracerLigne(contexte, 10, 60, 40, 60, couleurLignes, 10);
        tracerLigne(contexte, 40, 60, 60, 40, couleurLignes, 10);
        tracerLigne(contexte, 40, 60, 60, 80, couleurLignes, 10);
        
        // deux traits horizontaux au dessus et en dessous de l'auteur
        tracerLigne(contexte, 58, 40, largeurCartouche - 60, 40, couleurLignes, 10);
        tracerLigne(contexte, 58, 80, largeurCartouche - 60, 80, couleurLignes, 10);

        // puis deux autres traits obliques à droite et un dernier vertical pour fermer le dessin
        tracerLigne(contexte, largeurCartouche - 62, 40, largeurCartouche - 40, 60, couleurLignes, 10);
        tracerLigne(contexte, largeurCartouche - 62, 80, largeurCartouche - 40, 60, couleurLignes, 10);
        tracerLigne(contexte, largeurCartouche - 40, 60, largeurCartouche - 10, 60, couleurLignes, 10);
        
        // on trace le fond en rectangle azvec bords arrondis et un ombrage intérieur gauche / haut
        tracerRectangle(contexte, 10, 5, largeurCartouche - 20, hauteurCartouche - 10, "#F7F6F2", 10, 5, "#804D47");
    }, [ hauteurCartouche, largeurCartouche ]);

    // JSX du composant
    return (
        <>
            <Box onClick={actionClick} sx={{ height: "100px", cursor: curseur }} >
                <Canevas dessiner={dessiner} hauteur={hauteurCartouche} largeur={largeurCartouche} sx={{ zIndex:100 }}/>
                <Typography component="p"
                    sx={{ fontFamily: "PoliceTitre", fontSize: "1.5em", zIndex:200, position:"relative", top: "-100px", cursor: curseur }} align="center" 
                >
                    "{morceau.titre}"
                </Typography>
                <Typography component="p" 
                    sx={{ fontFamily: "PoliceAuteur", fontSize: "0.7em", zIndex:300, position:"relative", top: "-93px", cursor: curseur }} align="center"
                >
                    {morceau.symbolesGauche}{morceau.auteur}{morceau.symbolesDroite}
                </Typography>
            </Box>
        </>
    );
}

export default Cartouche;